import {
    Box,
    Flex,
    CardFooter,
    Text,
    Card,
    CardHeader,
    Icon,
    Heading,
    SimpleGrid,
    CardBody,

} from "@chakra-ui/react";

import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoShieldSharp,
} from "react-icons/io5";
import { FaUserTie, FaRegChartBar } from "react-icons/fa";
import { GiPodiumWinner } from "react-icons/gi";

import { TextIcon } from "../../components/symbol/TextIcon";


export default function TargetGroup() {
    return (
        <Box maxW="7xl" minH="30vh" pt={{ base: "5vh", sm: "5vh", lsm: "5vh", md: "0vh" }} px={{ base: 5, sm: 5, md: 50 }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>

                <Card
                    bg={"pElementTransparent.895"}
                    borderRadius="lg"
                    _hover={{
                        textDecoration: "none",
                        bg: "whiteAlpha.50",
                    }}
                    transition="all 1s"
                    px={{ base: "0vh", sm: "0vh", md: "0vh" }}
                >
                    <CardHeader >
                        <Heading
                            as='h1'
                            size='lg'
                            pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                            color={"gray.200"}
                            fontWeight={"bold"}
                            textAlign={"center"}
                            noOfLines={2}
                        >
                            You are a trader?
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <TextIcon
                            icon={
                                <Icon as={FaRegChartBar} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                            }
                        />                    </CardBody>
                    <CardFooter>
                        <Text fontSize={{ base: 'lg', sm: 'xl', lsm: 'xl', md: 'xl' }} px={{ base: "0px", sm: "0px", md: "5px" }}>If you are an experienced trader, you have the opportunity to leverage your profits through the use of margin.
                            You can share your strategies and performance on Fidenaro. If other Fidenaro users are conviced by your performance, they can entrust you with their capital to trade with.
As an experienced trader, you can enhance your profits by sharing your strategies and performance on Fidenaro. If other Fidenaro users are convinced by your results, they can choose to replicate your trades. In return, you'll earn a share of the profits generated from their copy trading.
                            <br />
                        </Text>
                    </CardFooter>
                </Card>
                <Card
                    bg={"pElementTransparent.895"}
                    borderRadius="lg"
                    _hover={{
                        textDecoration: "none",
                        bg: "whiteAlpha.50",
                    }}
                    transition="all 1s"
                >
                    <CardHeader >
                        <Heading
                            as='h1'
                            size='lg'
                            pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                            color={"gray.200"}
                            fontWeight={"bold"}
                            textAlign={"center"}
                            noOfLines={2}
                        >
                            You are an follower?
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <TextIcon
                            icon={
                                <Icon as={FaUserTie} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                            }
                        />
                    </CardBody>
                    <CardFooter>
                        <Text fontSize={{ base: 'lg', sm: 'xl', lsm: 'xl', md: 'xl' }} px={{ base: "0px", sm: "0px", md: "5px" }}>Use marketvolatility to your benefit through our Traders expertise.
                            Fidenaro helps you monitoring the individual traders strategies and success, to create the transparency needed for you to invest. Except for the trader's fee, you conveniently receive your profits directly to your wallet when you sell.
                        </Text>
                    </CardFooter>
                </Card>
            </SimpleGrid>

            <SimpleGrid pt={{ base: 5, lg: 8 }} columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>

                <Card
                    bg={"pElementTransparent.895"}
                    borderRadius="lg"
                    align={"center"}
                    _hover={{
                        textDecoration: "none",
                        bg: "whiteAlpha.50",
                    }}
                    transition="all 1s"
                >
                    <CardHeader >
                        <Heading
                            as='h1'
                            size='lg'
                            pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                            color={"gray.200"}
                            fontWeight={"bold"}
                            textAlign={"center"}
                            noOfLines={2}
                        >
                            Everyone benefits
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <TextIcon
                            icon={
                                <Icon as={GiPodiumWinner} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                            }
                        />
                    </CardBody>
                    <CardFooter>
                        <Text fontSize={{ base: 'lg', sm: 'xl', lsm: 'xl', md: 'xl' }}
                            align={{ base: "left", md: "center" }}
                            px={{ base: "0px", sm: "0px", md: "5px" }}
                        >
                            ```suggestion:-0+0
This approach benefits both parties. Experienced traders share their knowledge and strategies, allowing followers to replicate their trades through copy trading. When followers successfully copy these strategies, traders earn a share of the profits.
                        </Text>
                    </CardFooter>
                </Card>
            </SimpleGrid>
        </Box >
    );
}
