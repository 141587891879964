// general
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';

// components
import TopNavigationBar from '../components/navigation/TopNavigationBar';
import BottomNavigationBar from '../components/navigation/BottomNavigationBar';

// pages
import Home from './home/Home';

class Layout extends React.Component {

    render() {
        return <BrowserRouter>
            <TopNavigationBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="roadmap" element={<div>2</div>} />
                <Route path="tokenomics" element={<div>3</div>} />
                <Route path="about" element={<div>4</div>} />
                <Route path="contact" element={<div>5</div>} />
                <Route path="team" element={<div>6</div>} />
                <Route
                    path="*"
                    element={
                        <main style={{ padding: "1rem" }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>
            <BottomNavigationBar />
        </BrowserRouter>
    }
}
export default Layout;
