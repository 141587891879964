import {
    Box,
    Text,
    Card,
    CardHeader,
    Icon,
    Heading,
    SimpleGrid,
    CardBody,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoShieldSharp,
} from "react-icons/io5";
import {
    FaDollarSign,
    FaChartLine
} from "react-icons/fa";
import { BsSafeFill } from "react-icons/bs";
import { useDimensions } from "../../libs/size/Dimensions";
import { useViewportScroll } from "../../libs/size/ScrollSize";
import { TextIcon } from "../../components/symbol/TextIcon";
import { Divider } from '@chakra-ui/react'



export default function Benefits() {

    const benefitsAnchor = useRef(null);


    const scrollY = useViewportScroll()
    const [shouldHide, setShouldHide] = useState(false);

    const { x, y } = useViewportScroll();
    const { width, height } = useDimensions();

    useEffect(() => {
        // Hide the element if the user has scrolled 100 pixels down
        //  <SlideFade in={shouldHide} offsetY='200px' >
        const handleScroll = () => {
            if (y > 350) {
                setShouldHide(true);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [scrollY]);


    return (
        <Box maxW="7xl" minH="30vh" pt={{ base: "1vh", sm: "3vh", lsm: "3vh", md: "5vh" }} px={{ base: 12, sm: 25, md: 50 }}>
            <Heading
                as='h1'
                size='2xl'
                pb={{ base: "5vh", sm: "5vh", md: "5vh" }}
                color={"gray.200"}
                fontWeight={"bold"}
                textAlign={"center"}
                noOfLines={2}
                ref={benefitsAnchor}
            >
                <Text>User benefits</Text>
            </Heading>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>

                <Card bg={"whiteTransparent.0"}>
                    <CardHeader >
                        <TextIcon
                            icon={
                                <Icon as={FaDollarSign} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                            }
                        />
                    </CardHeader>
                    <CardBody>
                        <Text fontSize={{ base: 'lg', sm: '2xl', lsm: '2xl', md: 'xl' }} align={"center"}><Text as='b'>Profit by using the expertise</Text> and <Text as='b'>time</Text> of professional traders</Text>
                    </CardBody>
                </Card>
                <Card bg={"whiteTransparent.0"}>
                    <CardHeader >
                        <TextIcon
                            icon={
                                <Icon as={FaChartLine} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                            }
                        />
                    </CardHeader>
                    <CardBody>
                        <Text fontSize={{ base: 'lg', sm: '2xl', lsm: '2xl', md: 'xl' }} align={"center"}>Increase your <Text as='b'>profits</Text> through <Text as='b'>smart leveraging</Text></Text>
                    </CardBody>
                </Card>
                <Card bg={"whiteTransparent.0"}>
                    <CardHeader >
                        <TextIcon
                            icon={
                                <Icon as={BsSafeFill} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                            }
                        />
                    </CardHeader>
                    <CardBody>
                        <Text fontSize={{ base: 'lg', sm: '2xl', lsm: '2xl', md: 'xl' }} align={"center"}><Text as='b'>Total security</Text> through <Text as='b'>transparency</Text>, control and the right <Text as='b'>technologies</Text></Text>
                    </CardBody>
                </Card>
            </SimpleGrid>
        </Box >
    );
}
