import {
    Box,
    Text,
    Card,
    Grid,
    GridItem,
    Icon,
    Heading,
    SimpleGrid,
    CardBody,
    Center,
    useBreakpointValue,
    CardFooter,
    CardHeader,
} from "@chakra-ui/react";
import {
    IoAnalyticsSharp,
    IoRocketSharp,
    IoShieldSharp,
    IoSearchSharp,
} from "react-icons/io5";
import { TextIcon } from "../../components/symbol/TextIcon";
import { TextIconSizeAble } from "../../components/symbol/TextIconSizeAble";


export default function Adds() {

    const isSmallerThanMd = useBreakpointValue({ base: true, sm: true, lsm: true, md: false });

    return (
        <Box maxW="7xl" minH="30vh" pt={{ base: "5vh", sm: "5vh", lsm: "5vh", md: "5vh" }} px={{ base: 5, sm: 5, md: 50 }}>
            <Heading as='h1' size='2xl' pb={{ base: "5vh", sm: "5vh", md: "5vh" }} color={"gray.200"} fontWeight={"bold"} textAlign={"center"} noOfLines={2}>
                Safe for all
            </Heading>
            <Box w='1px' h='1px' boxShadow={{ base: "50vh 50vh 100px 30px #6B5EFF", sm: "5vh 0vh 100px 30px #6B5EFF", md: "55vh -12vh 100px 30px #6B5EFF" }}>
            </Box>
            <Box w='1px' h='1px' boxShadow={"-15vh 30vh 500px 30px #6B5EFF"} >
            </Box>
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>


                { // first card
                    isSmallerThanMd ?
                        <Card
                            bg={"pElementTransparent.895"}
                            borderRadius="lg"
                            align={"center"}
                            _hover={{
                                textDecoration: "none",
                                bg: "whiteAlpha.50",
                                boxShadow: "0 0 10px 0px #6B5EFF"
                            }}

                            transition="all 1s"
                        >
                            <CardHeader >
                                <Heading
                                    as='h1'
                                    size='lg'
                                    pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                                    color={"gray.200"}
                                    fontWeight={"bold"}
                                    textAlign={"center"}
                                    noOfLines={2}
                                >
                                    Transparency strengthens trust
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <TextIcon
                                    icon={
                                        <Icon as={IoSearchSharp} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                                    }
                                />
                            </CardBody>
                            <CardFooter>
                                <Text fontSize={{ base: 'lg', sm: 'xl', lsm: 'xl', md: 'xl' }} px={{ base: "0px", sm: "0px", md: "5px" }}>
                                    By disclosing the trader's trading history and performance,
                                    each follower is free to decide which trader to follow for their trades.
                                </Text>
                            </CardFooter>
                        </Card>

                        // bigger then md
                        :

                        <Card
                            bg={"pElementTransparent.895"}
                            borderRadius="lg"
                            align={"center"}
                            _hover={{
                                textDecoration: "none",
                                bg: "whiteAlpha.50",
                                boxShadow: "0 0 10px 0px #6B5EFF"
                            }}
                            transition="all 1s"
                        >


                            <CardBody>
                                <Grid
                                    templateRows='repeat(1, 1fr)'
                                    templateColumns='repeat(2, 1fr)'
                                    gap={0}
                                >
                                    <GridItem colSpan={2}>
                                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 0, lg: 0 }}>
                                            <Heading
                                                as='h1'
                                                size='lg'
                                                pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                                                color={"gray.200"}
                                                fontWeight={"bold"}
                                                textAlign={"left"}
                                                noOfLines={2}
                                                px={{ base: 0, sm: 0, md: 5 }}
                                            >
                                                Transparency strengthens trust
                                            </Heading>
                                            <Text fontSize={"xl"} align={{ base: "left", md: "left" }} px={{ base: 0, sm: 0, md: 5 }}>
                                                By disclosing the trader's trading history and performance,
                                                each follower is free to decide which trader to follow for their trades.
                                            </Text>
                                        </SimpleGrid>
                                    </GridItem>
                                    <GridItem colStart={4} colEnd={6} px={{ base: 0, sm: 0, md: 15 }}>
                                        <Center>
                                            <TextIconSizeAble
                                                icon={<Icon as={IoSearchSharp}
                                                    color={"pElement.200"}
                                                    w={{ base: 10, sm: 10, md: 24 }}
                                                    h={{ base: 10, sm: 10, md: 24 }}
                                                />}
                                                bw={16}
                                                bh={16}
                                                sw={16}
                                                sh={16}
                                                mw={40}
                                                mh={40} />
                                        </Center>
                                    </GridItem>
                                </Grid>


                            </CardBody>
                        </Card>
                }




                { // middle card
                    isSmallerThanMd ?
                        <Card
                            bg={"pElementTransparent.895"}
                            borderRadius="lg"
                            align={"center"}
                            _hover={{
                                textDecoration: "none",
                                bg: "whiteAlpha.50",
                                boxShadow: "0 0 10px 0px #6B5EFF"
                            }}

                            transition="all 1s"
                        >
                            <CardHeader >
                                <Heading
                                    as='h1'
                                    size='lg'
                                    pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                                    color={"gray.200"}
                                    fontWeight={"bold"}
                                    textAlign={"center"}
                                    noOfLines={2}
                                >
                                    Security through smart contracts
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <TextIcon
                                    icon={
                                        <Icon as={IoShieldSharp} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                                    }
                                />
                            </CardBody>
                            <CardFooter>
                                <Text fontSize={{ base: 'lg', sm: 'xl', lsm: 'xl', md: 'xl' }} px={{ base: "0px", sm: "0px", md: "5px" }}>
                                    All transactions and outcomes are secured along the chain and managed by smart contracts. This means that both sides are fully protected.
                                </Text>
                            </CardFooter>
                        </Card>

                        // bigger then md
                        :

                        <Card
                            bg={"pElementTransparent.895"}
                            borderRadius="lg"
                            align={"center"}
                            _hover={{
                                textDecoration: "none",
                                bg: "whiteAlpha.50",
                                boxShadow: "0 0 10px 0px #6B5EFF"
                            }}
                            transition="all 1s"
                        >


                            <CardBody>
                                <Grid
                                    templateRows='repeat(1, 1fr)'
                                    templateColumns='repeat(2, 1fr)'
                                    gap={0}
                                >
                                    <GridItem colStart={0} colEnd={1} px={{ base: 0, sm: 0, md: 15 }}>
                                        <Center>
                                            <TextIconSizeAble
                                                icon={<Icon as={IoShieldSharp}
                                                    color={"pElement.200"}
                                                    w={{ base: 10, sm: 10, md: 24 }}
                                                    h={{ base: 10, sm: 10, md: 24 }}
                                                />}
                                                bw={16}
                                                bh={16}
                                                sw={16}
                                                sh={16}
                                                mw={40}
                                                mh={40} />
                                        </Center>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Heading
                                            as='h1'
                                            size='lg'
                                            pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                                            color={"gray.200"}
                                            fontWeight={"bold"}
                                            textAlign={"left"}
                                            noOfLines={2}
                                            px={{ base: 0, sm: 0, md: 5 }}
                                            pl={{ base: 5, sm: 5, md: 5 }}
                                        >
                                            Security through smart contracts
                                        </Heading>
                                        <Text fontSize={"xl"} align={{ base: "left", md: "left" }} px={{ base: 0, sm: 0, md: 5 }}>
                                            All transactions and outcomes are secured along the chain and managed by smart contracts. This means that both sides are fully protected.
                                        </Text>
                                    </GridItem>

                                </Grid>


                            </CardBody>
                        </Card>
                }


                { // last card
                    isSmallerThanMd ?
                        <Card
                            bg={"pElementTransparent.895"}
                            borderRadius="lg"
                            align={"center"}
                            _hover={{
                                textDecoration: "none",
                                bg: "whiteAlpha.50",
                                boxShadow: "0 0 10px 0px #6B5EFF"
                            }}

                            transition="all 1s"
                        >
                            <CardHeader >
                                <Heading
                                    as='h1'
                                    size='lg'
                                    pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                                    color={"gray.200"}
                                    fontWeight={"bold"}
                                    textAlign={"center"}
                                    noOfLines={2}
                                >
                                    Ready for the future
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <TextIcon
                                    icon={
                                        <Icon as={IoRocketSharp} color={"pElement.200"} w={{ base: 16, sm: 24 }} h={{ base: 16, sm: 24 }} />
                                    }
                                />
                            </CardBody>
                            <CardFooter>
                                <Text fontSize={{ base: 'lg', sm: 'xl', lsm: 'xl', md: 'xl' }} px={{ base: "0px", sm: "0px", md: "5px" }}>With Radix as a powerful foundation, there is nothing to stop scaling in the future.
                                    Even with high usage, transaction fees can be expected to remain low.
                                    Radix also offers Fidenaro and all users the best opportunities to add more functionality.
                                </Text>
                            </CardFooter>
                        </Card>

                        // bigger then md
                        :

                        <Card
                            bg={"pElementTransparent.895"}
                            borderRadius="lg"
                            align={"center"}
                            _hover={{
                                textDecoration: "none",
                                bg: "whiteAlpha.50",
                                boxShadow: "0 0 10px 0px #6B5EFF"
                            }}
                            transition="all 1s"

                        >
                            <CardBody>
                                <Grid
                                    templateRows='repeat(1, 1fr)'
                                    templateColumns='repeat(2, 1fr)'
                                    gap={0}
                                >
                                    <GridItem colSpan={2}>
                                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 0, lg: 0 }}>
                                            <Heading
                                                as='h1'
                                                size='lg'
                                                pb={{ base: "3vh", sm: "3vh", md: "3vh" }}
                                                color={"gray.200"}
                                                fontWeight={"bold"}
                                                textAlign={"left"}
                                                noOfLines={2}
                                                px={{ base: 0, sm: 0, md: 5 }}
                                            >
                                                Ready for the future
                                            </Heading>
                                            <Text
                                                fontSize={"xl"}
                                                align={{ base: "left", md: "left" }}
                                                px={{ base: 0, sm: 0, md: 5 }}>
                                                With Radix as a powerful foundation, there is nothing to stop scaling in the future.
                                                Even with high usage, transaction fees can be expected to remain low.
                                                Radix also offers Fidenaro and all users the best opportunities to add more functionality.
                                            </Text>
                                        </SimpleGrid>
                                    </GridItem>
                                    <GridItem colStart={4} colEnd={6} px={{ base: 0, sm: 0, md: 15 }}>
                                        <Center>
                                            <TextIconSizeAble
                                                icon={<Icon as={IoRocketSharp}
                                                    color={"pElement.200"}
                                                    w={{ base: 10, sm: 10, md: 24 }}
                                                    h={{ base: 10, sm: 10, md: 24 }}
                                                />}
                                                bw={16}
                                                bh={16}
                                                sw={16}
                                                sh={16}
                                                mw={40}
                                                mh={40} />
                                        </Center>
                                    </GridItem>
                                </Grid>


                            </CardBody>
                        </Card>
                }


            </SimpleGrid>
        </Box >
    );
}
