import {
    Flex,
    Stack,
} from "@chakra-ui/react";
import { ReactElement } from "react";


interface FeatureProps {
    icon?: ReactElement;
    bw: any;
    bh: any;
    sw: any;
    sh: any;
    mw: any;
    mh: any;
}

export const TextIconSizeAble = ({ icon, bw, bh, sw, sh, mw, mh }: FeatureProps) => {
    return (
        <Stack color={"gray.200"} align={"center"}>
            <Flex
                w={{ base: bw, sm: sw, md: mw }}
                h={{ base: bh, sm: sh, md: mh }}
                align={"center"}
                justify={"center"}
                rounded={"full"}
                bg={"pElementTransparent.890"}
            >
                {icon}
            </Flex>
        </Stack>
    );
};