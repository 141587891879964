import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    Button,
    Menu,
    Image,
    Text,
    useDisclosure,
    Stack,
    Center,
    Divider,
    Icon,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { FaTwitter, FaDiscord, FaBookOpen, FaTasks, FaTelegram } from "react-icons/fa";
import { IoRocketSharp } from "react-icons/io5";
import {
    boxNavBar,
    fidenaorLogo,
    buttonLinkTobNavBar,

    hamburgerMenu,
    hamburgerMenuLinks,
} from "../../libs/styles/Elements";
import ScrollButton from "../button/ScrollButton";






export default function TopNavigationBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const closeMenu = () => {
        onClose();
    };
    return (
        <>
            <Box sx={boxNavBar}>
                <Center>
                    <Flex
                        maxW={"6xl"}
                        width={"100vw"}
                        h={16}
                        alignItems={"center"}
                        align={"center"}
                        justifyContent={"space-between"}
                    >
                        <HStack spacing={8} alignItems={"center"}>
                            <Box>
                                <Link href={"#"}>
                                    <Image
                                        sx={fidenaorLogo}
                                        src="/images/LogoFidenaro.png"
                                        alt="Fidenaro Logo"
                                    />
                                </Link>
                            </Box>
                            <HStack
                                as={"nav"}
                                spacing={1}
                                display={{ base: "none", md: "flex" }}
                            >
                                <Menu>
                                    <Button
                                        as="a"
                                        href="https://docs.fidenaro.com"
                                        sx={buttonLinkTobNavBar}
                                        title="Documentation"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaBookOpen />
                                        <Text pl={3}>Documentation</Text>

                                    </Button>
                                    <Button
                                        as={Link}
                                        href="#roadmap"
                                        sx={buttonLinkTobNavBar}
                                        title="Roadmap"
                                    >
                                        <FaTasks />
                                        <Text pl={3}>Roadmap</Text>

                                    </Button>
                                    <Button
                                        as="a"
                                        href="https://twitter.com/fidenaro/"
                                        sx={buttonLinkTobNavBar}
                                        title="Twitter"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTwitter />
                                        <Text pl={3}>Twitter</Text>

                                    </Button>
                                    <Button
                                        as="a"
                                        href="https://discord.gg/hMB6V4jHH8"
                                        sx={buttonLinkTobNavBar}
                                        title="Discord"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaDiscord />
                                        <Text pl={3}>Discord</Text>

                                    </Button>
                                    <Button
                                        as="a"
                                        href="https://t.me/Fidenaro"
                                        sx={buttonLinkTobNavBar}
                                        title="Telegram"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTelegram />
                                        <Text pl={3}>Telegram</Text>

                                    </Button>
                                </Menu>
                            </HStack>
                        </HStack>
                        <ScrollButton />
                        <IconButton
                            icon={isOpen ? <CloseIcon boxSize={5} /> : <HamburgerIcon boxSize={7} />}
                            aria-label={"Open Menu"}
                            sx={hamburgerMenu}
                            display={{ md: "none" }}
                            onClick={isOpen ? onClose : onOpen}
                        />
                    </Flex>
                </Center>

                {isOpen ? (
                    <Box pb={4} display={{ md: "none" }}>
                        <Stack as={"nav"} spacing={4}>
                            <Divider />
                            <Button
                                as="a"
                                href="https://docs.fidenaro.com"
                                sx={hamburgerMenuLinks}
                                title="Documentation"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={closeMenu}
                            >
                                <FaBookOpen /><Text px={3}>Documentation</Text>
                            </Button>
                            <Divider />
                            <Button
                                as={Link}
                                href="#roadmap"
                                sx={hamburgerMenuLinks}
                                title="Roadmap"
                                onClick={closeMenu}
                            >
                                <FaTasks /><Text px={3}>Roadmap</Text>
                            </Button>
                            <Divider />
                            <Button
                                as="a"
                                href="https://twitter.com/fidenaro/"
                                sx={hamburgerMenuLinks}
                                title="Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={closeMenu}
                            >
                                <FaTwitter /> <Text px={3}>Twitter</Text>
                            </Button>
                            <Divider />
                            <Button
                                as="a"
                                href="https://discord.gg/hMB6V4jHH8"
                                sx={hamburgerMenuLinks}
                                title="Discord"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={closeMenu}
                            >
                                <FaDiscord /> <Text px={3}>Discord</Text>
                            </Button>
                            <Divider />
                            <Button
                                as="a"
                                href="https://t.me/Fidenaro"
                                sx={hamburgerMenuLinks}
                                title="Telegram"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={closeMenu}
                            >
                                <FaTelegram /> <Text px={3}>Telegram</Text>
                            </Button>
                            <Divider />
                        </Stack>
                    </Box>
                ) : null}
            </Box>
            <Box
                bg={"back.900"}
                as="header"
                w="100%"
                px={4}
            >
                <Flex
                    h={16}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                ></Flex>
            </Box>
        </>
    );
}


