import { Box, useColorModeValue, Text, Center } from "@chakra-ui/react";
import { styleBoxBack700, styleBoxBack800, styleBoxBack900, styleCenterBox } from "../../libs/styles/Pages";
import Adds from "../benefits/Adds";
import Benefits from "../benefits/Benefits";
import TargetGroup from "../benefits/TargetGroup";
import Feature from "../feature/Feature";
import Roadmap from "../roadmap/Roadmap";
import Start from "../start/Start";
import Stats from "../stats/Stats";


import {
  IoRocketSharp,
  IoSearchSharp,
} from "react-icons/io5";

export default function Home() {
  return (
    <>
      <Box sx={styleBoxBack900}>
        <Center>
          <Box sx={styleCenterBox}>
            <Start />
          </Box>
        </Center>
      </Box>
      <Box id="benefits" sx={styleBoxBack900}>
        <Center>
          <Box sx={styleCenterBox}>
            <Benefits />
          </Box>
        </Center>
      </Box>
      <Box sx={styleBoxBack900}>
        <Center>
          <Box sx={styleCenterBox}>
            <TargetGroup />
          </Box>
        </Center>
      </Box>
      <Box sx={styleBoxBack900}>
        <Center>
          <Box sx={styleCenterBox}>
            <Adds />
          </Box>
        </Center>
      </Box>
      <Box sx={styleBoxBack900}>
        <Center>
          <Box sx={styleCenterBox}>
            <Roadmap />
          </Box>
        </Center>
      </Box>
    </>
  );
}
