import {
    Link,
    Button,
} from "@chakra-ui/react";
import { normalButton } from "../../libs/styles/Elements";

interface ScrollToNextAnchorButtonProps {
    href: string;
    text: string;
}

export function ReadLink({ href, text }: ScrollToNextAnchorButtonProps) {
    function handleClick() {
        const target = document.querySelector<HTMLAnchorElement>(href);
        const offset = target?.offsetTop ?? 0;

        if (offset !== undefined) {
            window.scrollTo({
                top: offset - 50,
                behavior: "smooth"
            });
        }
    }

    return (
        <Button
            //as={Link}
            //href={href}
            sx={normalButton}
            size={{ base: 'sm', sm: 'sm', lsm: 'md', md: 'md' }}
            title="Read more"
            onClick={handleClick}
        >
            {text}
        </Button>
    );
}



