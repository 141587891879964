
import {
    Box,
    Heading,
    Flex,
    Icon,
    Text,
    chakra,
    Center,
    HStack,
    VStack,
    useColorModeValue,
    useBreakpointValue,
} from "@chakra-ui/react";
import {
    IoRocketSharp,
    IoSearchSharp,
} from "react-icons/io5";


import { } from "@chakra-ui/react";
import React from 'react';
import { TextIcon } from "../../components/symbol/TextIcon";
import { TextIconSizeAble } from "../../components/symbol/TextIconSizeAble";

const milestones = [
    {
        id: 1,
        date: 'Q4, 2023 | Beta Launch',
        title: 'SAPPHIRE',
        description: `With the launch of the open beta, you will be able to test the features of Fidenaro. You can familiarize yourself with the functions and give us the chance to further improve Fidenaro. Of course, testing is done with virtual money.`,
        reached: true
    },
    {
        id: 2,
        date: 'Q3, 2024 | Update of the Beta',
        title: 'SAPPHIRE V2',
        description: `Stay tuned for the latest update of our beta.`,
        reached: false
    },
    {
        id: 3,
        date: 'Q4, 2024 | Fidenaro Launch',
        title: 'EMERALD',
        description: `Be part on the road to Fidenaro. With the launch of the platform, you as a trader or follower can use the platform to its full extent and with real money.`,
        reached: false
    },
];

export default function Roadmap() {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isDesktop = useBreakpointValue({ base: false, md: true });

    return (
        <>
            <div id="roadmap"></div>
            <Box maxW="7xl" minH="30vh" pt={{ base: "5vh", sm: "5vh", lsm: "5vh", md: "5vh" }} px={{ base: 5, sm: 5, md: 50 }}>
                <Heading as='h1' size='2xl' pb={{ base: "5vh", sm: "5vh", md: "5vh" }} color={"gray.200"} fontWeight={"bold"} textAlign={"center"} noOfLines={2}>
                    Roadmap
                </Heading>
                {milestones.map((milestone) => (
                    <Flex key={milestone.id} mb="10px">
                        {/* Desktop view(left card) */}
                        {isDesktop && milestone.id % 2 === 0 && (
                            <>
                                <EmptyCard />
                                <LineWithDot {...milestone} />
                                <Card {...milestone} />
                            </>
                        )}

                        {/* Mobile view */}
                        {isMobile && (
                            <>
                                <LineWithDot {...milestone} />
                                <Card {...milestone} />
                            </>
                        )}

                        {/* Desktop view(right card) */}
                        {isDesktop && milestone.id % 2 !== 0 && (
                            <>
                                <Card {...milestone} />

                                <LineWithDot {...milestone} />
                                <EmptyCard />
                            </>
                        )}
                    </Flex>
                ))}


            </Box >
        </>
    );
}


interface CardProps {
    id: number;
    title: string;
    description: string;
    date: string;
    reached: boolean;
}

const Card = ({ id, title, description, date, reached }: CardProps) => {
    // For even id show card on left side
    // For odd id show card on right side
    const isEvenId = id % 2 == 0;
    let borderWidthValue = isEvenId ? '15px 15px 15px 0' : '15px 0 15px 15px';
    let leftValue = isEvenId ? '-15px' : 'unset';
    let rightValue = isEvenId ? 'unset' : '-15px';

    const isMobile = useBreakpointValue({ base: true, md: false });
    if (isMobile) {
        leftValue = '-15px';
        rightValue = 'unset';
        borderWidthValue = '15px 15px 15px 0';
    }

    return (
        <HStack
            flex={1}
            p={{ base: 3, sm: 6 }}
            bg={"pElementTransparent.880"}
            spacing={5}
            rounded="lg"
            alignItems="center"
            pos="relative"
            transition="all 1s"
            _hover={{
                textDecoration: "none",
                bg: "whiteAlpha.50",
            }}
            _before={{
                content: `""`,
                w: '0',
                h: '0',
                borderColor: `transparent ${useColorModeValue('#eee', '#2C2851')} transparent`,
                borderStyle: 'solid',
                borderWidth: borderWidthValue,
                position: 'absolute',
                left: leftValue,
                right: rightValue,
                display: 'block'
            }}
        >
            <Box>
                <Text fontSize="lg" color={isEvenId ? 'pElement.200' : 'pElement.200'}>
                    {date}
                </Text>

                <VStack spacing={2} mb={3} textAlign="left">
                    <chakra.h1 fontSize="2xl" lineHeight={1.2} fontWeight="bold" w="100%">
                        {title}
                    </chakra.h1>
                    <Text fontSize="md">{description}</Text>
                </VStack>
            </Box>
        </HStack>
    );
};

const LineWithDot = ({ id, title, description, date, reached }: CardProps) => {
    return (
        <Flex
            pos="relative"
            alignItems="center"
            mr={{ base: '40px', md: '40px' }}
            ml={{ base: '0', md: '40px' }}
        >
            <chakra.span
                position="absolute"
                left="50%"
                height="calc(100% + 10px)"
                border="1px solid"
                borderColor={'pElementTransparent.880'}
                top="0px"
            ></chakra.span>
            <Box pos="relative" p="15px">
                <Box
                    pos="absolute"
                    top="0"
                    left="0"
                    bottom="0"
                    right="0"
                    width="100%"
                    height="100%"
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"

                    border={"2px"}
                    borderColor={"pElement.200"}
                    bg={'back.900'}
                    borderRadius="100px"
                    backgroundImage="none"
                    opacity={1}
                    boxShadow={"0vh 0vh 20px 5px #6B5EFF"}
                > {reached ? (
                    <Box
                        pos="absolute"
                        top="5px"
                        left="5px"
                        bottom="0"
                        right="0"
                        width="60%"
                        height="60%"
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center center"

                        border={"2px"}
                        borderColor={"pElement.200"}
                        //bg={'back.900'}
                        bg={'#6B5EFF'}
                        borderRadius="100px"
                        backgroundImage="none"
                        opacity={1}
                        boxShadow={"0vh 0vh 20px 5px #6B5EFF"}
                    ></Box>
                ) : (
                    null
                )}
                </Box>
            </Box>
        </Flex>
    );
};

const EmptyCard = () => {
    return <Box flex={{ base: 0, md: 1 }} p={{ base: 0, md: 6 }} bg="transparent"></Box>;
};