import {
    Box,
    chakra,
    Button,
    Text,
    Stack,
    Stat,
    Link,
    Heading,
    Show,
    useColorModeValue,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
    normalButton,
    specialButton,
} from "../../libs/styles/Elements";
import { ReadLink } from "../../components/button/ReadLink";


export default function Start() {

    return (

        <Box maxW="7xl" minH="90vh" pt={{ base: "20vh", sm: "20vh", lsm: "30vh", md: "40vh" }} px={{ base: 8, sm: 8, md: 50 }}>
            <Stack direction={['column', 'row']}>

                <Box maxW="xl">
                    <Heading as='h1' size={{ base: 'md', sm: 'lg', lsm: 'xl', md: 'xl' }} color={"gray.200"} fontWeight={"bold"} textAlign={"left"} noOfLines={2}>
                        <Text>Your social trading platform</Text>
                    </Heading>
                    <br></br>
                    <Text fontSize={{ base: 'md', sm: 'lg', lsm: 'xl', md: '2xl' }}>Benefit from the knowledge of experienced crypto traders through social trading with Fidenaro.</Text>
                    <Text fontSize={{ base: 'md', sm: 'lg', lsm: 'xl', md: '2xl' }}>Secure and decentralised on Radix.</Text>
                </Box>
                <Box w='1px' h='1px' boxShadow={{ base: "-20vh 0vh 100px 30px #6B5EFF", sm: "5vh 0vh 100px 30px #6B5EFF", md: "-20vh 0vh 100px 30px #6B5EFF" }}>
                </Box>
                <Box w='1px' h='1px' boxShadow={"20vh 40vh 500px 30px #6B5EFF"} >
                </Box>
                <Box w='1px' h='1px' boxShadow={"65vh -20vh 800px 30px #6B5EFF"} >
                </Box>
            </Stack>
            <br></br>
            <Button
                as={Link}
                href="https://app.fidenaro.com"
                sx={specialButton}
                size={{ base: 'sm', sm: 'sm', lsm: 'md', md: 'md' }}
                title="Fidenaro Beta App"
                target="_blank"
                rel="noopener noreferrer"
            >
                Try the Beta
            </Button>
            <ReadLink href="#benefits" text="Read more" />
        </Box>

    );
}
