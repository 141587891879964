import {
    Flex,
    Stack,
} from "@chakra-ui/react";
import { ReactElement } from "react";


interface FeatureProps {
    icon?: ReactElement;
}

export const TextIcon = ({ icon }: FeatureProps) => {
    return (
        <Stack color={"gray.200"} align={"center"}>
            <Flex
                w={{ base: 32, sm: 40 }}
                h={{ base: 32, sm: 40 }}
                align={"center"}
                justify={"center"}
                rounded={"full"}
                bg={"pElementTransparent.890"}
            >
                {icon}
            </Flex>
        </Stack>
    );
};