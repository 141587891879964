// pages
import Layout from './pages/Layout';


function Fidenaro() {
  return (
    <Layout />
  );
}

export default Fidenaro;
