
// base box
export const styleBoxBack900 = {
    bg: "back.900",
};

export const styleBoxBack800 = {
    bg: "back.800",
};

export const styleBoxBack700 = {
    bg: "back.700",
};


export const styleCenterBox = {
    maxW: "6xl",
    minH: "xl",
    width: "100vw",
}