import {
    Box,
    chakra,
    Container,
    Link,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from '@chakra-ui/react';
import { FaTwitter, FaBookOpen, FaDiscord, FaTelegram } from 'react-icons/fa';
import { IoHome } from 'react-icons/io5';

import { StyledLink, styleSocialButton } from '../../libs/styles/Elements';
import { SocialButton } from '../button/SocialButton';


export default function BottomNavigationBar() {
    return (
        <Box
            pb="20px"
            pt="5vh"
            bg={"back.900"}
            color={"font.300"}>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                spacing={4}
                justify={'center'}
                align={'center'}>
                <Box>


                </Box>
                <Stack direction={'row'} spacing={6}>
                    <SocialButton label={'Fidenaro'} href={'#'}>
                        <IoHome />
                    </SocialButton>
                    <SocialButton label={'Documentation'} href={'https://docs.fidenaro.com'}>
                        <FaBookOpen />
                    </SocialButton>
                    <SocialButton label={'Twitter'} href={'https://twitter.com/fidenaro/'}>
                        <FaTwitter />
                    </SocialButton>
                    <SocialButton label={'Discord'} href={'https://discord.gg/hMB6V4jHH8'}>
                        <FaDiscord />
                    </SocialButton>
                    <SocialButton label={'Telegram'} href={'https://t.me/Fidenaro'}>
                        <FaTelegram />
                    </SocialButton>


                </Stack>
            </Container >

            <Box
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.700', 'back.600')}>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ base: 'center', md: 'center' }}
                    align={{ base: 'center', md: 'center' }}>
                    <Text>© 2024 Fidenaro</Text>
                </Container>
            </Box>
        </Box >
    );
}