import {
    Button,
    Link
} from "@chakra-ui/react";
import { specialButton } from "../../libs/styles/Elements";
import { ArrowForwardIcon } from "@chakra-ui/icons";


import { Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useDimensions } from "../../libs/size/Dimensions";
import { useViewportScroll } from "../../libs/size/ScrollSize";


export default function ScrollButton() {
    const scrollY = useViewportScroll()
    const [shouldHide, setShouldHide] = useState(true);

    const { x, y } = useViewportScroll();
    const { width, height } = useDimensions();

    useEffect(() => {
        // Hide the element if the user has scrolled 100 pixels down
        const handleScroll = () => {
            if (y < height - 50) {
                setShouldHide(true);
            } else {
                setShouldHide(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [scrollY]);

    return (
        <>
            {!shouldHide ? (
                <Button
                    as={Link}
                    href="https://app.fidenaro.com"
                    sx={specialButton}
                    title="Fidenaro Beta App"
                    rightIcon={<ArrowForwardIcon />}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Try the Beta
                </Button>
            ) : null}
        </>
    );
}

export function TestScroll() {
    const { x, y } = useViewportScroll();

    return (
        <Box bg={"gray"}>
            <p>Scroll position: ({x}, {y})</p>
        </Box>
    );
}
